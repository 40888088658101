import React, { PropsWithChildren, ElementType } from 'react';
import Link from 'next/link';

import { ButtonStyle } from '../../button/Button';
import { TextSize } from '../../../models/enum';

import {
  CardList,
  CardListItem,
  CardMessage,
  CardActionButton,
  CardCenteredMessage,
  CardContentWrapper,
  CardIconContainer,
  CardTitle,
} from './CardError.style';
import { useRouter } from 'next/router';
import { defaultInternationalization } from 'packages/corporate-types/bin';

export interface CardErrorProps {
  icon: ElementType;
  title: string;
  message?: string;
  actionText: string;
}

const CardError: React.FC<PropsWithChildren<CardErrorProps>> = ({
  children,
  icon: Icon,
  title,
  message,
  actionText,
}) => {
  const { locale } = useRouter();

  return (
    <CardContentWrapper>
      <CardIconContainer>
        <Icon />
      </CardIconContainer>
      {title && <CardTitle>{title}</CardTitle>}
      {message && <CardCenteredMessage>{message}</CardCenteredMessage>}
      {children}
      <Link
        href={`/${
          locale && locale !== 'default' ? locale : defaultInternationalization
        }`}
        passHref
      >
        <CardActionButton
          asA={true}
          styleBtn={ButtonStyle.primary}
          text={actionText}
          fullWidth
          textSize={TextSize.small}
        />
      </Link>
    </CardContentWrapper>
  );
};

export { CardMessage, CardList, CardListItem };

export default CardError;
