import { styled } from '@mui/material';
import { ContentWrapper } from '../../../styles/shared';
import Button from '../../button/Button';

export const CardContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  padding: `${theme.spacings.unit(11)} ${theme.spacings.unit(9)}`,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  [theme.breakpointsMediaQuery.up('sm')]: {
    maxWidth: '50%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(11)} ${theme.spacings.unit(9)}`,
    maxWidth: '40%',
  },
}));

export const CardIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  svg: {
    height: theme.spacings.unit(21),
    width: theme.spacings.unit(21),
  },
}));

export const CardTitle = styled('h1')(({ theme }) => ({
  margin: `${theme.spacings.unit(6)} 0 0`,
  fontSize: theme.fonts.utility.size(10),
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(12),
  },
}));

export const CardCenteredMessage = styled('p')(({ theme }) => ({
  margin: `${theme.spacings.unit(4)} 0`,
}));

export const CardMessage = styled(CardCenteredMessage)(({ theme }) => ({
  textAlign: 'start',
  margin: `${theme.spacings.unit(4)} 0 0`,
}));

export const CardList = styled('ul')(({ theme }) => ({
  textAlign: 'start',
}));

export const CardListItem = styled('li')({});

export const CardActionButton = styled(Button)(() => ({
  borderRadius: 0,
}));
