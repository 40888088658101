import React from 'react';
import { NextPage } from 'next/types';
import { GetStaticProps } from 'next/types';

import { SentimentVeryDissatisfiedOutlinedIcon } from '../assets';
import {
  CardCenteredMessage,
  CardList,
} from '../components/cards/card-error/CardError.style';
import CardError from '../components/cards/card-error/CardError';
import { ImageWrapper } from '../styles/shared';
import { getAllTranslations } from '../utils/localize';
import { internationalizationToLanguages } from 'corporate-utils';
import { defaultInternationalization, defaultLanguage } from 'corporate-types';
import { NextSeo } from 'next-seo';
import { DEFAULT_IMAGE } from '../models/domain/const';
import ImageWithFallback from '../components/image-with-fallback/ImageWithFallback';
import { getLogo } from '../utils/media';
import { getCompany } from '../utils/company';
import { Companies } from '../models/domain/enum';

const Custom404: NextPage<{
  translations: any;
  locale: string;
  isPrerenderRequest: boolean;
}> = ({ translations, isPrerenderRequest }) => {
  const { title, message, actionText } =
    translations?.pages?.errors?.notFound ?? {};
  const company = getCompany();

  return (
    <>
      <NextSeo title={process?.env?.NEXT_PUBLIC_COMPANY_NAME}></NextSeo>
      <ImageWrapper>
        <ImageWithFallback
          src={getLogo(company)}
          width={
            company === Companies.SIRMAN
              ? '197px'
              : company === Companies.FELSINEA
                ? '233px'
                : '197px'
          }
          height="74px"
          alt="error_logo"
          fallbackSrc={DEFAULT_IMAGE}
          isPrerenderRequest={isPrerenderRequest}
        />
      </ImageWrapper>
      <CardError
        icon={SentimentVeryDissatisfiedOutlinedIcon}
        title={title ?? 'Page Not Found'}
        actionText={actionText ?? 'Go to home'}
      >
        <>
          <CardCenteredMessage>
            {message ?? 'The page you are looking for does not exist.'}
          </CardCenteredMessage>
          <CardList></CardList>
        </>
      </CardError>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const allTranslations = await getAllTranslations();
  const translations =
    allTranslations[
      internationalizationToLanguages(
        locale && locale !== 'default' ? locale : defaultInternationalization,
        '404'
      ) || defaultLanguage
    ];

  return {
    props: {
      translations,
    },
  };
};

export default Custom404;
